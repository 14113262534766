/* eslint-disable indent */
import "./TableTextInput.scss"
import React, { useEffect, useState } from 'react'

interface ITabletextInputProps {
  className?: string
  type: 'text' | 'number'
  placeholder?: string
  initialValue: string | number | undefined
  onBlur: (value: string | number | null | undefined) => void // Called only if validation succeeds.
  minValue?: number // Only applies when initialValue is a number.
  maxValue?: number // Only applies when initialValue is a number.
  allowEmpty?: boolean // If set to false, empty values will fail the validation.
  onInputErrorMessageChanged?: (message: string | null) => void // Message being null means that the error is resolved.
}

export default function TabletextInput({ 
    className,
    type,
    placeholder,
    initialValue,
    onBlur,
    minValue,
    maxValue,
    allowEmpty = true,
    onInputErrorMessageChanged
  }: ITabletextInputProps) {
  const [value, setValue] = useState<string | number>(initialValue || '')
  const [isInputError, setIsInputError] = useState<boolean>(false)

  useEffect(() => {
    setValue(initialValue || '')
    setIsInputError(false)
    if (onInputErrorMessageChanged !== undefined) {
      onInputErrorMessageChanged(null)
    }
  }, [initialValue])

  function handleInput(newValue: string) {
    switch (type) {
      case 'number': {
        const match = newValue.match(/([\d.,]*)/)
        if (!match) {
          return
        } 
        setValue(match[0].replace(',', '.'))
        break
      }
      default:
        setValue(value)
        break
    }
  }

  function handleBlur() {
    if (!validateAllowEmpty()) {
      return
    }

    switch (type) {
      case 'number': {
        // Map back to number if initial value was a number
        let numberValue
        switch (typeof value) {
          case 'number':
            numberValue = value
            break
          case 'string':
            numberValue = parseFloat(value)
            break
          default:
            throw new Error('Invalid value type')
        }
        if (validateNumber(numberValue)) {
          onBlur(numberValue || null)
        }
        break
      }
      default:
        onBlur(value)
        break
   }
  }

  function validateAllowEmpty() {
    if (!allowEmpty && value === '') {
      setIsInputError(true)
      if (onInputErrorMessageChanged !== undefined) {
        onInputErrorMessageChanged(`Du måste ange ett värde`)
      }
      return false
    }
    else {
      setIsInputError(false)
      return true
    }
  }

  function validateNumber(val: number) {
    if ((minValue !== undefined && val < minValue) || (maxValue !== undefined && val > maxValue)) {
      setIsInputError(true)
      if (onInputErrorMessageChanged !== undefined) {
        onInputErrorMessageChanged(`Du måste ange ett värde mellan ${minValue} och ${maxValue}`)
      }
      return false
    }
    else {  
      setIsInputError(false)
      if (onInputErrorMessageChanged !== undefined) {
        onInputErrorMessageChanged(null)
      }
      return true
    }
  }

  return <input
    className={`${className} ${isInputError ? 'table-text-input__error' : ''}`}
    placeholder={placeholder}
    value={value}
    onChange={(e) => handleInput(e.target.value)}
    onBlur={handleBlur}>
  </input>
}