import React from 'react'

export default function ArrowIconMinimal({ direction, size = '20px', color = 'var(--color-accent-red)' }: { direction: 'up' | 'right' | 'down' | "left", size?: string, color?: string }) {
  const computedSize = size.startsWith('var')
    ? getComputedStyle(document.documentElement).getPropertyValue(size.substring(4, size.length-1)).trim()
    : size

  const rotate = (direction: 'up' | 'right' | 'down' | "left") => {
    switch (direction) {
    case 'up':
      return 'rotate(-90deg)'
    case 'right':
      return 'rotate(0)'
    case 'down':
      return 'rotate(90deg)'
    case 'left':
      return 'rotate(180deg)'
    default:
      return 'rotate(0)'
    }
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={computedSize} height={computedSize} viewBox="0 0 32 32" fill="none" style={{ transform: `${rotate(direction)}` }}>
      <path d="M22.5 15.995C22.5 16.255 22.4 16.505 22.2101 16.705L12.9943 25.925C12.8944 26.025 12.7344 26.025 12.6445 25.925L11.575 24.865C11.475 24.765 11.475 24.605 11.575 24.515L20.0811 15.995L11.575 7.485C11.475 7.385 11.475 7.225 11.575 7.135L12.6345 6.075C12.7344 5.975 12.8944 5.975 12.9843 6.075L22.2001 15.285C22.4 15.485 22.49 15.735 22.49 15.995H22.5Z" fill={color}/>
    </svg>
  )
}
