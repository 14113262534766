import React, { useEffect, useState } from 'react'
import './GroceryFeed.scss'
import useSignalRFeed from '../../hooks/useSignalrFeed'
import StoreGroceryStock from '../../types/StoreGroceryStock'
import config from '../../config'
import { WebSocketInvocation } from '../../hooks/webSocketInvocation'
import SpinnerIcon from '../Icons/SpinnerIcon'
import { filterFeed, FilterOption, getFilterOptions } from './filterAndSortFeedItems'
import { get, post, put, remove } from '../../utils/http'
import { dateStringToRestockDisplayDate } from '../../utils/dateUtils'
import { groceryStockStyle, groceryStockText, isStockBelowRatio } from '../../utils/groceryStockUtils'
import Table, { IColumnConfig, IErrorRow } from '../Table/Table'
import { useStoreContext } from '../../contexts/store-context'
import { TOGO_CHANNEL_HEADER_VALUE } from '../../constants/channelConstants'
import { GroceryPriceInfo } from '../../types/GroceryPriceInfo'
import { useDeviceContext } from '../../contexts/device-context'
import GroceryFeedItemList from '../GroceryFeedItemList/GroceryFeedItemList'
import { CustomPrice } from '../../types/CustomPrice'
import TabletextInput from '../TableTextInput/TableTextInput'
import ResetIcon from '../Icons/ResetIcon'
import Modal from '../Modal/Modal'
import TrashIcon from '../Icons/TrashIcon'
import MinusCircleIcon from '../Icons/MinusCircleIcon'

export interface GroceryFeedItem {
  groceryStock: StoreGroceryStock
  basePriceInfo: GroceryPriceInfo
  customPrice: CustomPrice | null
}

export default function GroceryFeed() {
  const columnConfigs: IColumnConfig<GroceryFeedItem>[] = [
    {
      title: 'Artikel',
      content: item =>
        <div className='flex flex-row items-center grocery-feed__table_item_name'>
          <div className='min-w-[3em] min-h-[3em] max-w-[3em] max-h-[3em] flex items-center justify-center'>
            <img
              src={`https://assets.icanet.se/image/upload/w_128,c_scale/e_sharpen:80/f_auto,q_auto:best,dpr_1.0/${item.groceryStock.ean}.webp`}
              className='max-w-[3em] max-h-[3em] select-none'
              onError={({ currentTarget }) => {
                currentTarget.onerror = null // prevents looping
                currentTarget.src='https://assets.icanet.se/image/upload/w_128,c_scale/e_sharpen:80/f_auto,q_auto:best,dpr_1.0/v1/togostoredashboard/placeholder.webp'
              }}
            />
          </div>
          <span className='ml-3'>{item.groceryStock.name}</span>
        </div>,
      sortValue: item => item.groceryStock.name
    },
    {
      title: 'Ord. Pris kr',
      content: item => item.basePriceInfo.price.toString(),
      sortValue: item => item.basePriceInfo.price
    },
    {
      title: 'Lokalt Pris kr',
      content: item => 
        item.groceryStock.consumerItemId
          ? <div className='flex items-center'>
            <TabletextInput
              className='grocery-feed__local-price'
              type='number'
              placeholder={item.basePriceInfo.price.toString()}
              initialValue={item.customPrice?.price}
              onBlur={(newValSt) => handleCustomPriceSave(item, newValSt as number)}
              minValue={1}
              maxValue={99999}
              onInputErrorMessageChanged={(message) => handleTableInputErrorChange(item, 'customPrice.price', message)}/>
            {item.customPrice &&
            <div className='ml-3 tooltip'>
              <div className="tooltip-text">Återställ till ordinarie pris.</div>
              <div className='grocery-feed__clickable' onClick={() => handleCustomPriceDelete(item)}>
                <ResetIcon size='24px' color='var(--color-accent-red)'></ResetIcon>
              </div>
            </div>}
          </div>
          : <div className='flex'>
            <div className='tooltip'>
              <div className="tooltip-text">Produkten saknar ConsumerItemId, och kan därför inte ha ett lokalt pris. (Har den importerats från StoreOffice och datan är inte satt där?)</div>
              <MinusCircleIcon size='18px' color='var(--color-grey-light)' />
            </div>
          </div>,
      sortValue: item => item.customPrice?.price || item.basePriceInfo.price // TODO: Test
    },
    {
      title: 'Fylldes på',
      content: item => dateStringToRestockDisplayDate(item.groceryStock.restockedAt),
      sortValue: item => item.groceryStock.restockedAt
    },
    {
      title: 'Sålda',
      content: item => `${item.groceryStock.restockedQuantity - item.groceryStock.quantity} st`,
      sortValue: item => item.groceryStock.restockedQuantity - item.groceryStock.quantity
    },
    {
      title: 'Antal i skåp',
      content: item => `${item.groceryStock.quantity} st`,
      sortValue: item => item.groceryStock.quantity
    },
    {
      title: 'Status',
      content: item =>
        <>
          {isStockBelowRatio(item.groceryStock) &&
          <div className='flex'>
            <div className={`grocery__details-badge${groceryStockStyle(item.groceryStock)} content-center`}>
              <p>{groceryStockText(item.groceryStock)}</p>
            </div>
          </div>
          }
        </>
    },
    {
      content: item => <div className='grocery-feed__clickable' onClick={() => setSelectedGrocery(item)}><TrashIcon size='24px' /></div>
    },
  ]

  const invocations: Array<WebSocketInvocation<any>> = [{
    name: 'UpsertStoreGroceryStock',
    action: async (updatedFeedItem: StoreGroceryStock) => await handleGroceryStocksUpdate([updatedFeedItem])
  }, {
    name: 'UpsertStoreGroceryStocks',
    action: async (updatedFeedItems: Array<StoreGroceryStock>) => await handleGroceryStocksUpdate(updatedFeedItems)
  }]
  const { initialFeed: initialGroceryStockFeed, loading, error } = useSignalRFeed<StoreGroceryStock>(config.GROCERY_STOCK_FEED_SIGNALR_URL, `${config.GROCERY_STOCK_API_BASE_URL}/v1/grocerystock`, invocations)
  const [feed, setFeed] = useState<Array<GroceryFeedItem>>([])
  const [filteredFeed, setFilteredFeed] = useState<Array<GroceryFeedItem>>([])
  const [filterOptions, setFilterOptions] = useState<Array<FilterOption>>([])
  const [selectedFilterOption, setSelectedFilterOption] = useState(filterOptions[0])
  const [activeFilter, setActiveFilter] = useState<'all' | 'available' | 'almostOut' | 'out'>('all')
  const [selectedGrocery, setSelectedGrocery] = useState<GroceryFeedItem>()
  const { storeState } = useStoreContext()
  const { deviceState } = useDeviceContext()
  const [httpError, setHttpError] = useState<boolean>(false)
  const [httpLoading, setHttpLoading] = useState<boolean>(false)
  const [tableErrorRows, setTableErrorRows] = useState<IErrorRow<GroceryFeedItem>[]>([])

  // Filtering
  const changeFilter = (e: React.FormEvent<HTMLButtonElement>) => {
    const value = e.currentTarget.value
    const newFilterOption = filterOptions.find((o) => o.value === value)
    if (newFilterOption !== undefined) {
      setActiveFilter(newFilterOption.value)
      setSelectedFilterOption(newFilterOption)
      setFilteredFeed(filterFeed(feed, newFilterOption.value))
    } else {
      throw new Error("Filter selection: Selected value not found in given options array")
    }
  }

  async function handleGroceryStocksUpdate(updatedGroceryStocks: Array<StoreGroceryStock>): Promise<void> {
    const updatedFeedItems = await generateFeedItems(updatedGroceryStocks)
    setFeed((prevFeedState: Array<GroceryFeedItem>) => {
      const newFeed: GroceryFeedItem[] = [...prevFeedState]
      for (const updatedItem of updatedFeedItems) {
        const existingItemIndex: number = newFeed.findIndex(i => i.groceryStock.ean == updatedItem.groceryStock.ean)
        if (existingItemIndex !== -1) {
          newFeed[existingItemIndex] = updatedItem
        } else {
          newFeed.push(updatedItem)
        }
      }
      return newFeed
    })
  }

  async function generateFeedItems(groceryStockItems: StoreGroceryStock[]): Promise<GroceryFeedItem[]> {
    if (groceryStockItems.length === 0) {
      return []
    }
    else if (!storeState.selectedStore?.id) {
      throw("StoreId missing.")
    }

    // Get base grocery prices
    let groceryBasePrices: GroceryPriceInfo[]
    try {
      setHttpLoading(true)
      const basePriceParams = new URLSearchParams({ storeId: storeState.selectedStore?.id })
      groceryStockItems.map(g => basePriceParams.append('ean', g.ean))
      groceryBasePrices = await get<GroceryPriceInfo[]>(
        `${config.APPLICATION_API_BASE_URL}/groceries/prices?${basePriceParams}`,
        {
          headers: { 'x-client-name': TOGO_CHANNEL_HEADER_VALUE, }
        })
    }
    catch {
      setHttpError(true)
      return []
    }
    finally {
      setHttpLoading(false)
    }

    // Get custom prices
    let customPrices: CustomPrice[]
    try {
      setHttpLoading(true)
      const customPriceParams = new URLSearchParams({ storeId: storeState.selectedStore?.id, channelName: TOGO_CHANNEL_HEADER_VALUE })
      customPrices = await get<CustomPrice[]>(
        `${config.GROCERY_API_BASE_URL}/v1/groceries/custom-prices?${customPriceParams}`,)
    }
    catch {
      setHttpError(true)
      return []
    }
    finally {
      setHttpLoading(false)
    }

    // Merge with feed
    const feed: GroceryFeedItem[] = []
    groceryStockItems.forEach(gStock => {
      const priceInfo: GroceryPriceInfo = groceryBasePrices.find(p => p.ean === gStock.ean)!
      const customPrice: CustomPrice | undefined = customPrices.find(p => p.articleIdentifier === gStock.consumerItemId)
      feed.push({ groceryStock: gStock, basePriceInfo: priceInfo, customPrice: customPrice || null })
    })
    return feed
  }

  async function handleCustomPriceSave(item: GroceryFeedItem, newValue: number | null | undefined): Promise<void> {
    if (newValue === undefined) {
      return
    }
    else if (newValue === null) {
      if (item.customPrice) {
        await handleCustomPriceDelete(item)
      }
      return
    }
    else if (item.customPrice?.price === newValue) {
      return
    }

    const customerPriceToUpsert: CustomPrice = {
      articleIdentifier: item.groceryStock.consumerItemId,
      storeId: item.groceryStock.storeId,
      channelName: TOGO_CHANNEL_HEADER_VALUE,
      price: newValue
    }

    let upsertedCustomPrice: CustomPrice
    if (!item?.customPrice) {
      upsertedCustomPrice = await post(`${config.GROCERY_API_BASE_URL}/v1/groceries/custom-prices`, customerPriceToUpsert)
    }
    else {
      upsertedCustomPrice = await put(`${config.GROCERY_API_BASE_URL}/v1/groceries/custom-prices/${item.customPrice.storeId}/${item.customPrice.channelName}/${item.customPrice.articleIdentifier}`, customerPriceToUpsert)
    }

    const newFeed = [...feed]
    const itemToUpdate = newFeed.find(i => i.groceryStock.ean === item.groceryStock.ean)!
    itemToUpdate.customPrice = upsertedCustomPrice
    setFeed(newFeed)
  }

  async function handleCustomPriceDelete(item: GroceryFeedItem): Promise<void> {
    if (!item?.customPrice) {
      return
    }

    await remove(`${config.GROCERY_API_BASE_URL}/v1/groceries/custom-prices/${item.customPrice.storeId}/${item.customPrice.channelName}/${item.customPrice.articleIdentifier}`)
    
    const newFeed = [...feed]
    const itemToUpdate = newFeed.find(i => i.groceryStock.ean === item.groceryStock.ean)!
    itemToUpdate.customPrice = null
    setFeed(newFeed)
  }

  function handleTableInputErrorChange(item: GroceryFeedItem, errorPropName: string, message: string | null) {
    const existingIndex: number = tableErrorRows.findIndex(er => 
      er.itemDataValue(item) === er.errorDataValue && er.errorPropName === errorPropName)

    if (message !== null) {
      // There is an error; upsert it
      const newErrorRow: IErrorRow<GroceryFeedItem> = {
        errorPropName: errorPropName,
        itemDataValue: item => item.groceryStock.ean,
        errorDataValue: item.groceryStock.ean,
        errorMessage: message
      }

      if (existingIndex !== -1) {
        const newErrorRows = [...tableErrorRows]
        newErrorRows[existingIndex] = newErrorRow
        setTableErrorRows(newErrorRows)
      }
      else {
        setTableErrorRows([...tableErrorRows, newErrorRow])
      }
    }
    else {
      // No error; remove existing
      if (existingIndex !== -1) {
        const newErrorRows = [...tableErrorRows]
        newErrorRows.splice(existingIndex, 1)
        setTableErrorRows(newErrorRows)
      }
    }
  }
  
  async function deleteGrocery(grocery: GroceryFeedItem | undefined) {
    if (grocery === undefined) {
      return
    }

    setHttpLoading(true)
    setHttpError(false)
    try {
      await remove<any>(`${config.GROCERY_STOCK_API_BASE_URL}/v1/grocerystock/${grocery.groceryStock.storeId}/${grocery.groceryStock.ean}`)
      if (grocery.customPrice) {
        await handleCustomPriceDelete(grocery)
      }

      setSelectedGrocery(undefined)
      setFeed([...feed.filter(item => item.groceryStock.ean !== grocery.groceryStock.ean)])
      setFilterOptions(getFilterOptions(feed))
    } catch {
      setHttpError(true)
    }
    setHttpLoading(false)
  }

  // Effects
  useEffect(() => {
    async function setInitialFeedItems() {
      const newFeed: GroceryFeedItem[] = await generateFeedItems(initialGroceryStockFeed)
      setFeed([...newFeed])
    }
    setInitialFeedItems()
  }, [initialGroceryStockFeed])

  useEffect(() => {
    setFilteredFeed(filterFeed(feed, selectedFilterOption?.value))
    setFilterOptions(getFilterOptions(feed))
  }, [feed])

  return (
    <>
      {loading && !error && <div className='feed-spinner'><SpinnerIcon /> </div>}
      {feed &&
      <div className={`grocery-feed__filter ${deviceState.isMobile ? 'mt-1' : 'mt-8'}`}>
        <div className='mobile-scroll-horizontal flex items-center'>
          <p className='self-center select-none my-2 mr-2 ml-0 md:ml-2'>
            Visa:
          </p>
          <div className='flex'>
            {filterOptions.map((option) =>
              <div key={option.value} className='grocery-feed__quantity-filter-button'>
                <button id={option.value} value={option.value} className={`button-radio${activeFilter === option.value ? '-active' : ''}`} onClick={changeFilter}>{option.displayText}</button>
              </div>
            )}
          </div>
        </div>
      </div>}
      {deviceState.isMobile
        ? <GroceryFeedItemList
          groceries={filteredFeed}
          sortProps={[
            { name: 'Artikel', sortValue: item => item.groceryStock.name },
            { name: 'Ord. Pris', sortValue: item => item.basePriceInfo.price },
            { name: 'Sålda', sortValue: item => item.groceryStock.restockedQuantity - item.groceryStock.quantity },
            { name: 'Antal i skåp', sortValue: item => item.groceryStock.quantity }
          ]}
          saveCustomPrice={handleCustomPriceSave}
          deleteCustomPrice={handleCustomPriceDelete}
          deleteGrocery={deleteGrocery}/>
        : <>
          <Table className='w-full mt-6' data={filteredFeed} columnConfigs={columnConfigs} errorRows={tableErrorRows} />
          <Modal title='Är du säker?' show={!!selectedGrocery} onClose={() => setSelectedGrocery(undefined)}>
            <div className='flex items-center mt-4'>
              <button className='button grow mr-1' onClick={() => deleteGrocery(selectedGrocery)}>Ja</button>
              <button className='button grow ml-1' onClick={() => setSelectedGrocery(undefined)}>Nej</button>
            </div>
          </Modal>
        </>
      }
      {!loading && error && <p className='error'>Någonting gick fel. Försök igen genom att ladda om sidan.</p>}
      {!httpLoading && httpError && <p className='error'>Någonting gick fel. Försök igen genom att ladda om sidan.</p>}
    </>
  )
}
