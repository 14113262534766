/* eslint-disable indent */
//@ts-expect-error
const configFromServer = window.__CONFIG__ ?? { ENVIRONMENT: 'dev' }
const ENVIRONMENT = configFromServer.ENVIRONMENT
export interface Config {
  APPLICATION_API_BASE_URL: string,
  CHANNEL_API_BASE_URL: string,
  ENTRA_ID_LOGIN_URL: string,
  ENVIRONMENT: string,
  EVENT_API_BASE_URL: string,
  EVENT_FEED_SIGNALR_URL: string,
  GROCERY_API_BASE_URL: string,
  GROCERY_STOCK_API_BASE_URL: string,
  GROCERY_STOCK_FEED_SIGNALR_URL: string,
  LOGIN_URL: string,
  PRODUCTS_API_BASE_URL: string,
  STORE_API_BASE_URL: string,
}

const getAppConfig = (): Config => {
  switch (ENVIRONMENT) {
    case 'test':
      return {
        APPLICATION_API_BASE_URL: 'https://api-testing.icapronto.se',
        CHANNEL_API_BASE_URL: 'https://api-testing.icapronto.se/svc-channel',
        ENTRA_ID_LOGIN_URL: 'https://api-testing.icapronto.se/auth',
        ENVIRONMENT,
        EVENT_API_BASE_URL: 'https://api-testing.icapronto.se/app-store-event',
        EVENT_FEED_SIGNALR_URL: 'https://api-testing.icapronto.se/app-event/hubs/events',
        GROCERY_API_BASE_URL: 'http://api.dev.icanow.azure.icacorp.net',
        GROCERY_STOCK_API_BASE_URL: 'https://api-testing.icapronto.se/grocery-stock',
        GROCERY_STOCK_FEED_SIGNALR_URL: 'https://api-testing.icapronto.se/grocery-stock/hubs/groceries',
        LOGIN_URL: 'https://authentication-testing.icapronto.se/user/login',
        PRODUCTS_API_BASE_URL: 'https://api-testing.icapronto.se/app-food',
        STORE_API_BASE_URL: 'https://api-testing.icapronto.se/app-store',
      }
    case 'stage':
      return {
        APPLICATION_API_BASE_URL: 'https://api-staging.icapronto.se',
        CHANNEL_API_BASE_URL: 'https://api-staging.icapronto.se/svc-channel',
        ENTRA_ID_LOGIN_URL: 'https://api-staging.icapronto.se/auth',
        ENVIRONMENT,
        EVENT_API_BASE_URL: 'https://api-staging.icapronto.se/app-store-event',
        EVENT_FEED_SIGNALR_URL: 'https://api-staging.icapronto.se/app-event/hubs/events',
        GROCERY_API_BASE_URL: 'http://api.ver.icanow.azure.icacorp.net',
        GROCERY_STOCK_API_BASE_URL: 'https://api-staging.icapronto.se/grocery-stock',
        GROCERY_STOCK_FEED_SIGNALR_URL: 'https://api-staging.icapronto.se/grocery-stock/hubs/groceries',
        LOGIN_URL: 'https://authentication-staging.icapronto.se/user/login',
        PRODUCTS_API_BASE_URL: 'https://api-staging.icapronto.se/app-food',
        STORE_API_BASE_URL: 'https://api-staging.icapronto.se/app-store',
      }
    case 'prod':
      return {
        APPLICATION_API_BASE_URL: 'https://api.icapronto.se',
        CHANNEL_API_BASE_URL: 'https://api.icapronto.se/svc-channel',
        ENTRA_ID_LOGIN_URL: 'https://api.icapronto.se/auth',
        ENVIRONMENT,
        EVENT_API_BASE_URL: 'https://api.icapronto.se/app-store-event',
        EVENT_FEED_SIGNALR_URL: 'https://api.icapronto.se/app-event/hubs/events',
        GROCERY_API_BASE_URL: 'http://api.prod.icanow.azure.icacorp.net',
        GROCERY_STOCK_API_BASE_URL: 'https://api.icapronto.se/grocery-stock',
        GROCERY_STOCK_FEED_SIGNALR_URL: 'https://api.icapronto.se/grocery-stock/hubs/groceries',
        LOGIN_URL: 'https://authentication.icapronto.se/user/login',
        PRODUCTS_API_BASE_URL: 'https://api.icapronto.se/app-food',
        STORE_API_BASE_URL: 'https://api.icapronto.se/app-store',
      }
    default: // local
      return {
        APPLICATION_API_BASE_URL: 'http://localhost:8546',
        CHANNEL_API_BASE_URL: 'http://localhost:8559',
        ENTRA_ID_LOGIN_URL: 'http://localhost:8554',
        ENVIRONMENT,
        EVENT_API_BASE_URL: 'http://localhost:8552',
        EVENT_FEED_SIGNALR_URL: 'https://api-testing.icapronto.se/app-event/hubs/events',
        GROCERY_API_BASE_URL: 'http://api.dev.icanow.azure.icacorp.net',
        GROCERY_STOCK_API_BASE_URL: 'http://localhost:8564',
        GROCERY_STOCK_FEED_SIGNALR_URL: 'https://api-testing.icapronto.se/grocery-stock/hubs/groceries',
        LOGIN_URL: 'https://authentication-testing.icapronto.se/user/login',
        PRODUCTS_API_BASE_URL: 'http://localhost:8556',
        STORE_API_BASE_URL: 'http://localhost:8553',
      }
  }
}

const config = getAppConfig()

export { config }
export default config