import React, { useRef } from 'react'
import "./RefreshIcon.scss"

export default function RefreshIcon({ size = '20px', color = 'var(--color-black)', onClick = undefined, text = undefined }
  : { size?: string, color?: string, onClick?: () => void, text?: string }) {
  const ref = useRef<SVGSVGElement | null>(null)

  const handleOnClick = () => {
    if (!onClick || !ref?.current) {
      return
    }

    ref.current.classList.remove('refresh-icon-spin')
    ref.current.classList.add('refresh-icon-spin')
  
    onClick()
  }

  return (
    <div className={`refresh-icon-container flex items-center${onClick ? ' refresh-icon-clickable' : ''}`} onClick={handleOnClick}>
      <svg ref={ref} width={size} height={size} viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M67.083 416.655C57.8849 232.79 199.48 76.2812 383.345 67.0831C468.592 62.8185 548.025 91.0075 609.539 140.758C609.576 140.788 609.613 140.818 609.65 140.848L655.924 178.603L651.544 91.046C650.624 72.6595 664.784 57.0087 683.17 56.0889C701.557 55.169 717.208 69.3286 718.128 87.7151L726.255 250.179C727.175 268.565 713.015 284.216 694.629 285.136L531.167 293.313C512.78 294.233 497.129 280.073 496.209 261.687C495.29 243.3 509.449 227.65 527.836 226.73L604.739 222.883L567.616 192.594C567.599 192.579 567.581 192.565 567.563 192.551C518.317 152.743 454.887 130.254 386.676 133.666C239.584 141.025 126.308 266.232 133.666 413.324C141.025 560.417 266.232 673.692 413.324 666.334C540.033 659.995 641.717 566.172 662.697 446.223C665.869 428.088 683.141 415.959 701.275 419.131C719.409 422.303 731.539 439.575 728.367 457.709C702.14 607.655 575.136 724.989 416.655 732.917C232.79 742.115 76.2812 600.521 67.083 416.655Z" fill={color}/>
      </svg>
      {text && <span className='refresh-icon-text ml-1 select-none' style={{ color: color }}>{text}</span>}
    </div>
  )
}
