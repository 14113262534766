import ErrorIcon from "../Icons/ErrorIcon"
import "./InputError.scss"
import React from 'react'

interface IInputErrorProps {
  className?: string
  message?: string
}

export default function InputError({ className, message }: IInputErrorProps) {
  return <div className={`${className} input-error flex items-center gap-1`}>
    <ErrorIcon size='24px' color='var(--color-secondary-red)' />
    {message}
  </div>
}