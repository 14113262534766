import { User } from "../contexts/user-context"

export interface EntraIdTokenPayload {
  email: string | Array<string>
  role: string | Array<string>
}

export const getEntraIdUserFromToken = (token: string) => {
  const tokenPayloadBase64 = token.split('.')[1]
  const tokenPayload = JSON.parse(atob(tokenPayloadBase64)) as EntraIdTokenPayload

  let displayName
  if (Array.isArray(tokenPayload.email)) {
    displayName = tokenPayload.email[0]
  }
  else {
    displayName = tokenPayload.email
  }

  let roles
  if (Array.isArray(tokenPayload.role)) {
    roles = tokenPayload.role
  }
  else {
    roles = [tokenPayload.role]
  }

  return {
    displayName,
    token,
    roles,
  } as User
}