/* eslint-disable indent */
import React, { Fragment, useEffect, useState } from 'react'
import "./Table.scss"
import SortIcon from '../Icons/SortIcon'
import ArrowIconMinimal from '../Icons/ArrowIconMinimal'
import InputError from '../InputError/InputError'

export interface ITableProps<T> {
  className?: string
  data: T[]
  columnConfigs: Array<IColumnConfig<T>>,
  errorRows: Array<IErrorRow<T>>
}

export interface IErrorRow<T> {
  itemDataValue: (item: T) => any
  errorDataValue: any,
  errorPropName: string
  errorMessage: string
}

export interface IColumnConfig<T> {
  title?: string
  content: ((item: T) => string) | ((item: T) => React.JSX.Element)
  sortValue?: (item: T) => string | number
}

interface TableSort {
  columnId: number | undefined
  direction: 'asc' | 'desc'
}

export default function Table<T>({ className, data, columnConfigs, errorRows } : ITableProps<T>) {
  const [sortedData, setSortedData] = useState<T[]>([...data])
  const [currentSorting, setCurrentSorting] = useState<TableSort>({ columnId: undefined, direction: 'desc' })

  useEffect(() => {
    const newData: T[] = sort(data, currentSorting)
    setSortedData([...newData])
  }, [data])

  function updateSortingColumn(columnConfig: IColumnConfig<T>, columnId: number): void {
    if (!columnConfig.sortValue) {
      throw new Error('sortValue is required for sorting')
    }

    const newSorting: TableSort = 
    {
      columnId,
      direction: currentSorting.direction === 'asc' ? 'desc' : 'asc'
    }

    const newData: T[] = sort(sortedData, newSorting)
    setSortedData([...newData])
    setCurrentSorting(newSorting)
  }

  function sort(dataToSort: T[], sorting: TableSort): T[] {
    if (!dataToSort || dataToSort.length === 0) {
      return []
    }
    else if (sorting.columnId === undefined) {
      return dataToSort
    }

    const columnConfig: IColumnConfig<T> = columnConfigs[sorting.columnId]
    const sortModifier: number = sorting.direction === 'asc' ? 1 : -1

    return dataToSort.sort((a, b) => {
      const aVal: string | number = columnConfig.sortValue!(a)
      const bVal: string | number = columnConfig.sortValue!(b)
      switch (typeof(aVal)) {
        case 'string':
          return aVal.localeCompare(bVal as string, 'sv') * sortModifier
        case 'number':
          return (aVal as number - (bVal as number)) * sortModifier
      }
    })
  }

  return (
    <table className={`table ${className}`}>
      <thead>
        <tr>
          {columnConfigs.map((columnConfig, index) => (
            <th key={index} className={`select-none${columnConfig.sortValue ? ' table__sort-header': ''}`} onClick={() => columnConfig.sortValue ? updateSortingColumn(columnConfig, index) : undefined}>
              {columnConfig.sortValue
                ? <div className='flex items-center gap-1'>
                  <span>{columnConfig.title}</span>
                  <div className='table__sort-icon'>
                    {currentSorting.columnId === index
                      ? <ArrowIconMinimal size='16px' direction={currentSorting.direction === 'asc' ? 'down' : 'up'} color='var(--color-dark-gray)' />
                      : <SortIcon size='16px' />
                    }
                  </div>
                </div>
                : <span>{columnConfig.title}</span>
              }
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((item, rowIndex) => (
          <Fragment key={`${rowIndex}-wrapper`}>
            <tr key={rowIndex}>
              {columnConfigs.map((columnConfig, colIndex) => (
                <td key={`${rowIndex}-${colIndex}`}>
                  {columnConfig.content instanceof Function ? columnConfig.content(item) : columnConfig.content}
                </td>
            ))}
            </tr>
            {errorRows.filter(er => er.errorDataValue === er.itemDataValue(item)).map((errorRow) => (
              <Fragment key={`${rowIndex}-error-${errorRow.errorPropName}-wrapper`}>
                <tr key={`${rowIndex}-error-${errorRow.errorPropName}`} className='table__error-row'>
                  <td colSpan={columnConfigs.length}>
                    <InputError message={errorRow.errorMessage} />
                  </td>
                </tr>
                {/* Add extra tr to make every-other-row styling work consistently. */}
                <tr key={`${rowIndex}-error-${errorRow.errorPropName}-padding`} className='hidden'></tr>
              </Fragment>
            ))}
          </Fragment>
        ))}
      </tbody>
    </table>
  )
}