/* eslint-disable indent */
import './GroceryFeedItemList.scss'
import React, { useEffect, useState } from 'react'
import { GroceryFeedItem } from '../GroceryFeed/GroceryFeed'
import EditIcon from '../Icons/EditIcon'
import { groceryStockStyle, groceryStockText, isStockBelowRatio } from '../../utils/groceryStockUtils'
import Modal from '../Modal/Modal'
import ArrowIconMinimal from '../Icons/ArrowIconMinimal'
import { dateStringToRestockDisplayDate } from '../../utils/dateUtils'
import TabletextInput from '../TableTextInput/TableTextInput'
import InputError from '../InputError/InputError'
import ResetIcon from '../Icons/ResetIcon'

export interface IGroceryFeedItemList {
  groceries:  GroceryFeedItem[]
  sortProps?: GroceryFeedItemListSortProp[]
  saveCustomPrice(grocery: GroceryFeedItem, price: number | undefined): Promise<void>
  deleteCustomPrice(grocery: GroceryFeedItem): Promise<void>
  deleteGrocery: (grocery: GroceryFeedItem | undefined) => Promise<void>
}

export interface GroceryFeedItemListSortProp {
  name: string
  sortValue?: (item: GroceryFeedItem) => string | number
}

interface GroceryFeedItemListSortOption extends GroceryFeedItemListSortProp {
  direction: 'asc' | 'desc'
  value: string | undefined
}

export default function GroceryFeedItemList({
    groceries,
    sortProps,
    saveCustomPrice,
    deleteCustomPrice,
    deleteGrocery
  }: IGroceryFeedItemList) {
  const [sortedGroceries, setSortedGroceries] = useState<GroceryFeedItem[]>([...groceries])
  const [sortOptions, setSortOptions] = useState<GroceryFeedItemListSortOption[] | undefined>(undefined)
  const [currentSortOption, setCurrentSortOption] = useState<GroceryFeedItemListSortOption | undefined>(undefined)
  const [selectedGrocery, _setSelectedGrocery] = useState<GroceryFeedItem>()
  const setSelectedGrocery = (grocery: GroceryFeedItem | undefined) => {
    _setSelectedGrocery(grocery)
    if (grocery) {
      setCustomPrice(grocery!.customPrice?.price || grocery!.basePriceInfo.price)
    }
    else {
      setCustomPrice(undefined)
    }
  }
  const [customPrice, setCustomPrice] = useState<number>()
  const [customPriceError, setCustomPriceError] = useState<string | null>(null)
  const [deletingGrocery, setDeletingGrocery] = useState<boolean>(false)

  useEffect(() => {
    if (sortProps !== undefined) {
      const newSortOptions: GroceryFeedItemListSortOption[] = 
        [{ name: 'Sorteringsalternativ', direction: 'asc', value: undefined }]
      
      const options: GroceryFeedItemListSortOption[] = sortProps
        .flatMap(prop => {
          return [
            { ...prop, name: `${prop.name} - Stigande`, direction: 'asc', value: `${prop.name}-asc` },
            { ...prop, name: `${prop.name} - Fallande`,  direction: 'desc', value: `${prop.name}-desc` }
          ]
        })
      newSortOptions.push(...options)
      setSortOptions(newSortOptions)
    }
  }, [sortProps])

  useEffect(() => {
    const newSortedGroceries: GroceryFeedItem[] = sort(groceries, currentSortOption)
    setSortedGroceries([...newSortedGroceries])
    if (selectedGrocery) {
      const newSelectedGrocery: GroceryFeedItem | undefined = newSortedGroceries.find(g => g.groceryStock.ean === selectedGrocery.groceryStock.ean)
      setSelectedGrocery(newSelectedGrocery)
    }
  }, [groceries])

  function updateSorting(optionValue: string): void {
    const sortOption: GroceryFeedItemListSortOption | undefined = sortOptions!.find(option => option.value === optionValue)
    if (sortOption === undefined) {
      setSortedGroceries([...groceries])
      return
    }

    const newSortedGroceries: GroceryFeedItem[] = sort(sortedGroceries, sortOption)
    setSortedGroceries([...newSortedGroceries])	
    setCurrentSortOption(sortOption)
  }

  function sort(groceriesToSort: GroceryFeedItem[], sortOption: GroceryFeedItemListSortOption | undefined): GroceryFeedItem[] {
    if (!groceriesToSort || groceriesToSort.length === 0) {
      return []
    }
    if (sortOption === undefined) {
      return groceriesToSort
    }

    const sortModifier: number = sortOption.direction === 'asc' ? 1 : -1
    return groceriesToSort.sort((a, b) => {
      const aVal: string | number = sortOption.sortValue!(a)
      const bVal: string | number = sortOption.sortValue!(b)
      switch (typeof(aVal)) {
        case 'string':
          return aVal.localeCompare(bVal as string, 'sv') * sortModifier
        case 'number':
          return (aVal as number - (bVal as number)) * sortModifier
      }
    })
  }

  async function handleDelete(): Promise<void> {
    await deleteGrocery(selectedGrocery)
    setSelectedGrocery(undefined)
    setDeletingGrocery(false)
  }

  return (
    <>
      <div className='mt-2'></div>
      {sortOptions &&
      <div className='mb-1 flex items-center'>
        <div className='grocery-feed-item-list__filter-icon pointer-events-none'>
          <ArrowIconMinimal direction='down' size='var(--grocery-feed-item-list__filter-icon-size)' color='var(--color-accent-red)'/>
        </div>
        <select className='grocery-feed-item-list__filter select-none cursor-pointer appearance-none pl-5 py-2' onChange={(e) => updateSorting(e.target.value)}>
          {sortOptions.map((sortOption, index) => (
            <option key={index} value={sortOption.value}>{sortOption.name}</option>
        ))}
        </select>
      </div>
      }
      <div className='grocery-feed-item-list__container'>
        {sortedGroceries.map((grocery, index) => (
          <div key={index} className='grocery-feed-item-list__item flex flex-row' onClick={() => setSelectedGrocery(grocery)}>
            <div className='min-w-[3em] min-h-[3em] max-w-[3em] max-h-[3em] flex items-center self-center justify-center'>
              <img
                src={`https://assets.icanet.se/image/upload/w_128,c_scale/e_sharpen:80/f_auto,q_auto:best,dpr_1.0/${grocery.groceryStock.ean}.webp`}
                className='max-w-[3em] max-h-[3em] select-none'
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src='https://assets.icanet.se/image/upload/w_128,c_scale/e_sharpen:80/f_auto,q_auto:best,dpr_1.0/v1/togostoredashboard/placeholder.webp'
                }}
              />
            </div>
            <div className='ml-3'>
              <div className='grocery-feed-item-list__item-title'>{grocery.groceryStock.name}</div>
              <div className='mt-[6px] grocery-feed-item-list__item-text'>
                <span>Ord. pris</span>
                <span className='grocery-feed-item-list__item-text-bold'> {grocery.basePriceInfo.price}</span>
                {grocery.customPrice && 
                <>
                  <span className='ml-2'>Lokalt pris </span>
                  <span className='grocery-feed-item-list__item-text-bold'> {grocery.customPrice.price}</span>
                </>
                }
              </div>
              <div className='mt-[6px] grocery-feed-item-list__item-text'>
                <span>Sålda</span>
                <span className='grocery-feed-item-list__item-text-bold'> {grocery.groceryStock.restockedQuantity - grocery.groceryStock.quantity} st</span>
                <span className='ml-2'>I skåp</span>
                <span className='grocery-feed-item-list__item-text-bold'> {grocery.groceryStock.quantity} st</span>
              </div>
              {isStockBelowRatio(grocery.groceryStock) &&
              <div className='mt-[14px] flex'>
                <div className={`grocery__details-badge${groceryStockStyle(grocery.groceryStock)} content-center`}>
                  <p>{groceryStockText(grocery.groceryStock)}</p>
                </div>
              </div>
            }
            </div>
            <div className='flex-grow justify-items-end'>
              <EditIcon size='24px' />
            </div>
          </div>
        ))}
      </div>   
      {selectedGrocery &&  !deletingGrocery &&
        <Modal title='Hantera artikel' show={selectedGrocery !== null} onClose={() => setSelectedGrocery(undefined)}>
          <div className='flex flex-row items-center grocery-feed__table_item_name mt-4'>
            <div className='min-w-[3em] min-h-[3em] max-w-[3em] max-h-[3em] flex items-center justify-center'>
              <img
              src={`https://assets.icanet.se/image/upload/w_128,c_scale/e_sharpen:80/f_auto,q_auto:best,dpr_1.0/${selectedGrocery.groceryStock.ean}.webp`}
              className='max-w-[3em] max-h-[3em] select-none'
              onError={({ currentTarget }) => {
                currentTarget.onerror = null // prevents looping
                currentTarget.src='https://assets.icanet.se/image/upload/w_128,c_scale/e_sharpen:80/f_auto,q_auto:best,dpr_1.0/v1/togostoredashboard/placeholder.webp'
              }}
              />
            </div>
            <span className='ml-3'>{selectedGrocery.groceryStock.name}</span>
          </div>
          <hr className='mt-4'/>
          <div className='flex mt-4 grocery-feed-item-list__modal-info-text'>
            <span className='flex-grow'>Ord. pris kr</span>
            <span>{selectedGrocery.basePriceInfo.price}</span>
          </div>
          <hr className='mt-4'/>
          <div className='flex items-center mt-4 grocery-feed-item-list__modal-info-text'>
            <span className='flex-grow'>Lokalt pris kr</span>
            {selectedGrocery.customPrice &&
            <div className='grocery-feed__clickable mr-4' onClick={() => deleteCustomPrice(selectedGrocery)}>
              <ResetIcon size='24px' color='var(--color-accent-red)'></ResetIcon>
            </div>}
            <TabletextInput
              className='text-end grocery-feed__local-price'
              type='number'
              placeholder={selectedGrocery.basePriceInfo.price.toString()}
              initialValue={selectedGrocery.customPrice?.price}
              onBlur={(newValSt) => saveCustomPrice(selectedGrocery, newValSt as number)}
              minValue={1}
              maxValue={99999}
              onInputErrorMessageChanged={(message) => setCustomPriceError(message)} />
          </div>
          {customPriceError && <InputError className='mt-2' message={customPriceError} />}
          <hr className='mt-4'/>
          <div className='flex mt-4 grocery-feed-item-list__modal-info-text'>
            <span className='flex-grow'>Fylldes på</span>
            <span>{dateStringToRestockDisplayDate(selectedGrocery.groceryStock.restockedAt)}</span>
          </div>
          <hr className='mt-4'/>
          <div className='flex mt-4 grocery-feed-item-list__modal-info-text'>
            <span className='flex-grow'>Sålda</span>
            <span>{selectedGrocery.groceryStock.restockedQuantity - selectedGrocery.groceryStock.quantity} st</span>
          </div>
          <hr className='mt-4'/>
          <div className='flex mt-4 grocery-feed-item-list__modal-info-text'>
            <span className='flex-grow'>Antal i skåp</span>
            <span>{selectedGrocery.groceryStock.quantity} st</span>
          </div>
          <hr className='mt-4'/>
          <div className='flex items-center mt-6 mb-2'>
            <button className='button button-secondary w-100 mr-1 h-10' onClick={() => setDeletingGrocery(true)}>Ta bort artikel</button>
            <button className='button w-100 ml-1 h-10' onClick={() => setSelectedGrocery(undefined)}>Klar</button>
          </div>
        </Modal>
     }
      {selectedGrocery && deletingGrocery &&
      <Modal title='Är du säker?' show={selectedGrocery && deletingGrocery} onClose={() => setDeletingGrocery(false)}>
        <div className='flex items-center mt-4'>
          <button className='button grow mr-1' onClick={handleDelete}>Ja</button>
          <button className='button grow ml-1' onClick={() => setDeletingGrocery(false)}>Nej</button>
        </div>
      </Modal>}
    </>
  )
}