import StoreGroceryStock from "../types/StoreGroceryStock"

export const groceryStockStyle = (grocery: StoreGroceryStock) => {
  if (grocery.quantity === 0) {
    return '__out'
  } else if (isStockBelowRatio(grocery)) {
    return '__warn'
  }
}

export const groceryStockText = (grocery: StoreGroceryStock) => {
  if (grocery.quantity === 0) {
    return 'Slut'
  } else if (isStockBelowRatio(grocery)) {
    return 'Snart slut'
  }
}

export const isStockBelowRatio = (grocery: StoreGroceryStock) => {
  if (grocery.quantity === 0) {
    return true
  } else if (grocery.restockedQuantity === 1) {
    return false
  } else {
    return grocery.quantity <= ratio(grocery)
  }
}

export const ratio = (grocery: StoreGroceryStock) => {
  const defaultRatio: number = 0.2
  const lesserRatio: number = 0.3

  return grocery.restockedQuantity >= 1 && grocery.restockedQuantity <= 5
    ? Math.ceil(grocery.restockedQuantity * lesserRatio)
    : Math.ceil(grocery.restockedQuantity * defaultRatio)
}
