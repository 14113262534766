import React, { MouseEvent, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import "./Modal.scss"
import CloseIcon from '../Icons/CloseIcon'
import ScrollTrap from '../ScrollTrap/ScrollTrap'

export interface IModalProps {
  title: string
  show: boolean
  children: ReactNode
  onClose: () => void
}

export default function Modal ({ title, show, children, onClose }: IModalProps) {
  const portal = document.getElementById('modal-portal')
  const onBackgroundClicked = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    const element = event.target as HTMLDivElement
    if(element.id === 'modal-root') {
      onClose()
    }
  }

  return (
    show && portal ?
      ReactDOM.createPortal(
        <div className='modal grid place-content-center' id="modal-root" onClick={onBackgroundClicked}>
          <ScrollTrap>
            <div className='modal-container'>              
              <div className='border-bottom flex px-4 py-3'>
                <div className='modal-title'>
                  {title}
                </div>
                <div className='ml-auto'>
                  <div className='close-button' onClick={onClose}>
                    <CloseIcon size='20px' />
                  </div>
                </div>
              </div>
              <div className='px-5 pb-3'>
                {children} 
              </div>
            </div>
          </ScrollTrap>
        </div>, portal
      ) : null
  )
}
